import React, { useState } from "react";
import Logo from "../../../assets/logo.png";
import ProfilePick from "../../../assets/profilePick.png";
import { IoReorderThreeSharp } from "react-icons/io5";
import { IoIosArrowForward } from "react-icons/io";
import { IoMdLogIn } from "react-icons/io";
import { Link } from "react-router-dom";
const Navbar = () => {
  const [loginState, setLoginState] = useState(false);
  return (
    <div className="sticky z-10 top-0">
      <div className="lg:h-[85.566px] h-[60px] bg-white bg-opacity-20 backdrop-blur-lg rounded drop-shadow-lg p-5">
        <div className="flex  justify-between ">
          <Link to={"/"}>
            <img className="w-32 h-10 lg:h-12 lg:w-48" src={Logo} alt="logo" />
          </Link>
          {loginState ? (
            <div className="flex space-x-5 cursor-pointer ">
              <IoReorderThreeSharp className="text-[45px]  text-black" />
            <Link to={'/profile'}>
            <div className="flex space-x-2">
            <img
              className="h-[40px] w-[40px]"
              src={ProfilePick}
              alt="profile"
            />
            <IoIosArrowForward className="text-black text-[35px my-3" />
          </div>
            </Link>

              
            </div>
          ) : (
            <div className="flex  space-x-3 items-center">
              <Link to={"/login"}>
                <div className="flex  space-x-1 text-justify text-white font-bold lg:text-xl text-sm">
                  <button>Login</button>
                  <IoMdLogIn className="mt-1.5" />
                </div>
              </Link>
              <Link to={"/signup"}>
                <button className="bg-white lg:h-12 h-8 w-24 lg:w-36 font-semibold lg:text-lg text-sm rounded-full">
                  Get Started
                </button>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
